import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Typography,
  InputGroup,
  Tooltip,
  Modal,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '../';
import { downloadAllSelectedStudies } from '../../../../viewer/src/routes/WorkList/WorkList';
import { useAppConfig } from '@state';
import classnames from 'classnames';
import './../../../src/assets/styles/doots.css';

const baseInputClasses =
  'shadow transition duration-300 appearance-none border border-primary-main hover:border-gray-500 focus:border-gray-500 focus:outline-none rounded w-full py-2 px-3 text-sm text-white leading-tight focus:outline-none';
const transparentClasses = {
  true: 'bg-transparent',
  false: 'bg-black',
};
const spanClassName =
  'flex flex-row items-center cursor-pointer focus:outline-none';
const sortIconMap = {
  descending: 'sorting-active-up',
  ascending: 'sorting-active-down',
  none: 'sorting',
};

let FilterValues: any = {};

const StudyListFilter = ({
  filtersMeta,
  filterValues,
  onChange,
  clearFilters,
  clearAllFilters,
  isFiltering,
  numOfStudies,
  onUploadClick,
  checkedStudies,
  isChecked,
}) => {
  const { t } = useTranslation('StudyList');
  const { sortBy, sortDirection } = filterValues;
  const filterSorting = { sortBy, sortDirection };
  const setFilterSorting = sortingValues => {
    onChange({
      ...filterValues,
      ...sortingValues,
    });
  };

  FilterValues = filterValues;

  function CheckFilters() {
    if (
      filterValues.accession === '' &&
      filterValues.datasources !== '' &&
      filterValues.description === '' &&
      filterValues.modalities.length === 0 &&
      filterValues.mrn === '' &&
      filterValues.pageNumber === 1 &&
      filterValues.patientName === '' &&
      filterValues.resultsPerPage === 25 &&
      filterValues.sortBy === 'studyDate' &&
      filterValues.sortDirection === 'ascending' &&
      filterValues.studyDate.startDate === null &&
      filterValues.studyDate.endDate === null
    ) {
      return false;
    } else {
      return true;
    }
  }

  const isSortingEnabled = numOfStudies > 0 && numOfStudies <= 100;

  const [appConfig] = useAppConfig();

  const dsConfigs = appConfig.dataSources;
  const [isDownload, setIsDownload] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleDownloadClick = () => {
    const studies = checkedStudies;
    downloadAllSelectedStudies(studies, isDownload => {
      setIsDownload(isDownload);
    });
    setIsDownload(true);
  };

  const filteredDataSources = dsConfigs
    .filter(
      ({ sourceName }) =>
        sourceName !== 'dicomlocal' && sourceName !== 'dicomjson'
    )
    .sort((a, b) => (a.friendlyName > b.friendlyName ? 1 : -1));

  const [FilteringDataSources, setFilteringDataSources] = React.useState([]);
  const [FNsortDirection, setFNsortDirection] = React.useState('none');
  const [SNsortDirection, setSNsortDirection] = React.useState('none');

  function FilterwithFriendlyName(searchText) {
    setFilteringDataSources(
      filteredDataSources.filter(({ friendlyName }) =>
        friendlyName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }

  function FilterwithSourceName(searchText) {
    setFilteringDataSources(
      filteredDataSources.filter(({ sourceName }) =>
        sourceName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }

  function SortDataSources(sortingItem) {
    if (sortingItem === 'friendlyName') {
      setSNsortDirection('none');
      if (FNsortDirection === 'none' || FNsortDirection === 'ascending') {
        setFNsortDirection('descending');
        setFilteringDataSources(
          filteredDataSources.sort((a, b) =>
            b.friendlyName
              .toLowerCase()
              .localeCompare(a.friendlyName.toLowerCase())
          )
        );
      } else if (FNsortDirection === 'descending') {
        setFNsortDirection('ascending');
        setFilteringDataSources(
          filteredDataSources.sort((a, b) =>
            a.friendlyName
              .toLowerCase()
              .localeCompare(b.friendlyName.toLowerCase())
          )
        );
      }
    } else {
      setFNsortDirection('none');
      if (SNsortDirection === 'none' || SNsortDirection === 'ascending') {
        setSNsortDirection('descending');
        setFilteringDataSources(
          filteredDataSources.sort((a, b) =>
            b.sourceName.toLowerCase().localeCompare(a.sourceName.toLowerCase())
          )
        );
      } else if (SNsortDirection === 'descending') {
        setSNsortDirection('ascending');
        setFilteringDataSources(
          filteredDataSources.sort((a, b) =>
            a.sourceName.toLowerCase().localeCompare(b.sourceName.toLowerCase())
          )
        );
      }
    }
  }

  return (
    <React.Fragment>
      <div>
        <div className="bg-primary-dark">
          <div className="container relative flex flex-col pt-5 m-auto">
            <div className="flex flex-row justify-between mb-5">
              <div className="flex flex-row">
                <Typography variant="h4" className="mr-6 text-primary-light">
                  {t('StudyList')}
                </Typography>
                {onUploadClick && (
                  <div
                    className="flex items-center gap-2 cursor-pointer text-primary-active text-lg self-center font-semibold"
                    onClick={onUploadClick}
                  >
                    <Icon name="icon-upload"></Icon>
                    <span>Upload</span>
                  </div>
                )}
              </div>
              {/* for showing clear filters button */}
              <div className="flex flex-row">
                {CheckFilters() && isFiltering && (
                  <Button
                    rounded="full"
                    variant="outlined"
                    color="primaryActive"
                    border="primaryActive"
                    className="mx-1"
                    startIcon={<Icon name="cancel" />}
                    onClick={clearFilters}
                  >
                    {t('ClearFilters')}
                  </Button>
                )}
                {/* for showing the selected PACS servers */}
                {filterValues.datasources !== '' && (
                  <Button
                    rounded="full"
                    variant="outlined"
                    color="primaryActive"
                    border="primaryActive"
                    className="mx-1"
                    startIcon={<Icon name="cancel" />}
                    onClick={clearAllFilters}
                  >
                    {t(filterValues.datasources)}
                  </Button>
                )}
                {/* download button for downloading selected studies */}
                {isChecked && (
                  <Tooltip content="Download Selected Studies" position="left">
                    <Button
                      rounded="full"
                      variant="outlined"
                      color="primaryActive"
                      border="primaryActive"
                      bgColor={'primaryActive'}
                      className={classnames('mx-1 py-2.5', {})}
                      startIcon={<Icon name="download" />}
                      onClick={handleDownloadClick}
                    >
                      {t('Download')}
                    </Button>
                  </Tooltip>
                )}

                {/* button for selecting PACS Servers from the list */}
                <Button
                  rounded="full"
                  variant="outlined"
                  color="primaryActive"
                  border="primaryActive"
                  onClick={() => {
                    setFilteringDataSources(filteredDataSources);
                    setIsModalOpen(true);
                  }}
                  className="mx-1 mr-5"
                  startIcon={<Icon name="database" />}
                >
                  {t('Select PACS')}
                </Button>
                {/* for showing tooltip for the number of studies */}
                <Tooltip
                  content={<span>Total Studies: {numOfStudies}</span>}
                  position="left"
                >
                  <Typography
                    variant="h4"
                    className="mx-1"
                    data-cy={'num-studies'}
                  >
                    {numOfStudies > 1000
                      ? '1000+'
                      : numOfStudies > 900
                      ? '900+'
                      : numOfStudies > 800
                      ? '800+'
                      : numOfStudies > 700
                      ? '700+'
                      : numOfStudies > 600
                      ? '600+'
                      : numOfStudies > 500
                      ? '500+'
                      : numOfStudies > 400
                      ? '400+'
                      : numOfStudies > 300
                      ? '300+'
                      : numOfStudies > 200
                      ? '200+'
                      : numOfStudies > 100
                      ? '100+'
                      : numOfStudies}
                  </Typography>
                </Tooltip>

                {/* <Typography
                  variant="h6"
                  className="self-end pb-1 text-common-light"
                >
                  {t('Studies')}
                </Typography> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sticky z-10 border-b-4 border-black -top-1"
        // style={{ top: '52px' }}
      >
        <div className="pt-3 pb-3 bg-primary-dark ">
          <InputGroup
            inputMeta={filtersMeta}
            values={filterValues}
            onValuesChange={onChange}
            sorting={filterSorting}
            onSortingChange={setFilterSorting}
            isSortingEnabled={isSortingEnabled}
          />
        </div>
        {numOfStudies > 100 && (
          <div className="container m-auto">
            <div className="py-1 text-base text-center rounded-b bg-primary-main">
              <p className="text-white">
                {t('NumOfStudiesHiggerThan100Message')}
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Modal for showing Data Sources in a pop up */}
      <Modal
        title="PACS Servers List"
        isOpen={isModalOpen}
        shouldCloseOnEsc={true}
        closeButton={true}
        onClose={() => {
          setIsModalOpen(false);
          setFNsortDirection('none');
          setSNsortDirection('none');
        }}
        style={{ content: { width: '40%' } }}
      >
        <div className="drop-shadow-md space-y-2 rounded-lg">
          <div className="space-y-2">
            {/* table for showing textboxes for friendly name and source name */}
            <Table>
              <TableRow isTableHead={true}>
                <TableCell
                  cellsNum="1"
                  style={{ width: '3%', marginTop: '15px' }}
                >
                  {'#'}
                </TableCell>
                <TableCell cellsNum="2" style={{ width: '50%' }}>
                  <div className="flex">
                    <input
                      id="friendlyName"
                      className={classnames(
                        'border-primary-main mt-2 bg-black',

                        baseInputClasses,
                        transparentClasses
                      )}
                      onChange={({ target }) =>
                        FilterwithFriendlyName(target.value)
                      }
                      type="text"
                      placeholder="Friendly Name"
                    />
                    <span
                      role="button"
                      style={{ marginLeft: '-20px', marginTop: '8px' }}
                      className={spanClassName}
                      onClick={() => SortDataSources('friendlyName')}
                      onKeyDown={() => SortDataSources('friendlyName')}
                      tabIndex={0}
                    >
                      <Icon
                        name={sortIconMap[FNsortDirection]}
                        className={classnames(
                          'mx-1 w-2',
                          FNsortDirection !== 'none'
                            ? 'text-primary-light'
                            : 'text-primary-main'
                        )}
                      />
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  cellsNum="3"
                  style={{
                    width: '47%',
                  }}
                >
                  <div className="flex">
                    <input
                      id="sourceName"
                      className={classnames(
                        'border-primary-main mt-2 bg-black',

                        baseInputClasses,
                        transparentClasses
                      )}
                      onChange={({ target }) =>
                        FilterwithSourceName(target.value)
                      }
                      type="text"
                      placeholder="Source Name"
                    />
                    <span
                      role="button"
                      style={{ marginLeft: '-20px', marginTop: '8px' }}
                      className={spanClassName}
                      onClick={() => SortDataSources('sourceName')}
                      onKeyDown={() => SortDataSources('sourceName')}
                      tabIndex={0}
                    >
                      <Icon
                        name={sortIconMap[SNsortDirection]}
                        className={classnames(
                          'mx-1 w-2',
                          SNsortDirection !== 'none'
                            ? 'text-primary-light'
                            : 'text-primary-main'
                        )}
                      />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            </Table>
            <Table>
              <TableBody>
                {FilteringDataSources.map((ds, index) => (
                  <TableRow
                    onClick={() => {
                      window.location.href = `/?datasources=${ds.sourceName}`;
                      // navigate({
                      //   pathname: '/',
                      //   search: `datasources=${ds.sourceName}`,
                      // });
                      setIsModalOpen(false);
                    }}
                    className={classnames(
                      'border-b border-secondary-light cursor-pointer hover:bg-secondary-main transition duration-300 h-9 content-baseline py-2'
                    )}
                    key={ds.sourceName}
                    isTableHead={true}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell cellsNum="1" style={{ width: '3%' }}>
                      {index + 1}
                    </TableCell>
                    <TableCell cellsNum="2" style={{ width: '50%' }}>
                      {ds.friendlyName}
                    </TableCell>
                    <TableCell
                      className="px-3"
                      cellsNum="3"
                      style={{ width: '47%' }}
                    >
                      {ds.sourceName}
                    </TableCell>
                    {/* s */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Modal>

      {/* Modal for showing Downloading Studies Popup */}
      <Modal
        title="Studies Downloading..."
        isOpen={isDownload}
        shouldCloseOnEsc={true}
        closeButton={true}
        onClose={() => {
          setIsDownload(false);
        }}
      >
        <h4>
          Your studies are started downloading don{"'"}t close this alert and
          this page, if you want to use this application you can{' '}
          <a className="text-primary-active" href="/" target={'_blank'}>
            click here
            <Icon
              name="external-link"
              className="ml-1 w-5 text-white inline-block"
            />
          </a>{' '}
          to open a new page
          <svg
            style={{ display: 'inline-block', marginLeft: '3px' }}
            id="loading"
            viewBox="25 25 50 50"
          >
            <circle id="loadingCircle" cx="50" cy="50" r="20"></circle>
          </svg>
        </h4>

        <div className="flex justify-end">
          <Button
            color="primary"
            className="ml-2"
            onClick={() => {
              setIsDownload(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

StudyListFilter.propTypes = {
  filtersMeta: PropTypes.arrayOf(
    PropTypes.shape({
      /** Identifier used to map a field to it's value in `filterValues` */
      name: PropTypes.string.isRequired,
      /** Friendly label for filter field */
      displayName: PropTypes.string,
      /** One of the supported filter field input types */
      inputType: PropTypes.oneOf([
        'Text',
        'MultiSelect',
        'DateRange',
        // 'Checkbox',
        'None',
      ]).isRequired,
      isSortable: PropTypes.bool.isRequired,
      /** Size of filter field in a 12-grid system */
      gridCol: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
        .isRequired,
      /** Options for a "MultiSelect" inputType */
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  filterValues: PropTypes.object.isRequired,
  numOfStudies: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  onUploadClick: PropTypes.func,
  checkedStudies: PropTypes.array.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default StudyListFilter;

async function SendFilterValues() {
  return FilterValues;
}

export { SendFilterValues };
