import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Input } from '../';
import classnames from 'classnames';

// import { Input, InputLabelWrapper } from '../';

const baseLabelClassName = 'flex flex-col text-white text-lg select-none';
const spanClassName =
  'flex flex-row items-center cursor-pointer focus:outline-none';
const sortIconMap = {
  descending: 'sorting-active-up',
  ascending: 'sorting-active-down',
  none: 'sorting',
};

const InputText = ({
  id,
  label,
  isSortable,
  sortDirection,
  onLabelClick,
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  const { t } = useTranslation('StudyList');
  const onClickHandler = e => {
    if (!isSortable) {
      return;
    }
    onLabelClick(e);
  };

  return (
    <div className="flex">
      <Input
        id={id}
        className="border-primary-main mt-2 bg-black"
        type="text"
        containerClassName=""
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={event => {
          onChange(event.target.value);
        }}
      />
      <label
        className={classnames(baseLabelClassName)}
        style={{
          width: '24px',
          marginTop: '15px',
        }}
      >
        <span
          role="button"
          style={{ marginLeft: '-20px' }}
          className={spanClassName}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          tabIndex={0}
        >
          {t(label)}
          {isSortable && (
            <Icon
              name={sortIconMap[sortDirection]}
              className={classnames(
                'mx-1 w-2',
                sortDirection !== 'none'
                  ? 'text-primary-light'
                  : 'text-primary-main'
              )}
            />
          )}
        </span>
        {/* <span>{children}</span> */}
      </label>
    </div>
  );
};

InputText.defaultProps = {
  value: '',
  isSortable: false,
  onLabelClick: () => {},
  sortDirection: 'none',
};

InputText.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isSortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none']),
  onLabelClick: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputText;
