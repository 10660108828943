import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './../../assets/styles/doots.css';
import { extensionManager } from '../../../../viewer/src/App.tsx';

import { Button, Icon, Modal, Tooltip } from '../';

const baseClasses =
  'first:border-0 border-t border-secondary-light cursor-pointer select-none outline-none';

let dataSourceDetails;

const StudyItem = ({
  date,
  description,
  numInstances,
  modalities,
  trackedSeries,
  isActive,
  onClick,
}) => {
  dataSourceDetails = extensionManager;
  const [isDownload, setIsDownload] = React.useState(false);

  return (
    <div
      className={classnames(
        isActive ? 'bg-secondary-dark' : 'bg-black hover:bg-secondary-main',
        baseClasses
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <div className="flex flex-col flex-1 px-4 pb-2">
        <div className="flex flex-row items-center justify-between pt-2 pb-2">
          <div className="text-base text-white">{date}</div>
          <div className="flex flex-row items-center text-base text-blue-300">
            <Icon name="group-layers" className="w-4 mx-2 text-blue-300" />
            {numInstances}
          </div>
          <div className="flex flex-row items-center text-base text-blue-300">
            <Tooltip content="Download Entire Study" position="left">
              <Icon
                onClick={e => {
                  e.stopPropagation();
                  setIsDownload(true);

                  downloadEntireStudy(isDownload => {
                    setIsDownload(isDownload);
                  });
                }}
                name="download"
                className="w-4 mx-2 text-blue-300"
              />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-row py-1">
          <div className="pr-5 text-xl text-blue-300">{modalities}</div>
          <div className="text-base text-blue-300 break-words truncate-2-lines">
            {description}
          </div>
        </div>
      </div>
      {!!trackedSeries && (
        <div className="flex flex-2">
          <div
            className={classnames(
              'flex flex-row bg-secondary-main text-base text-white py-1 pl-2 pr-4 mt-2 ',
              isActive
                ? 'flex-1 border-t border-secondary-light justify-center'
                : 'rounded-sm mx-4 mb-4'
            )}
          >
            <Icon name="tracked" className="w-4 mr-2 text-primary-light" />
            {trackedSeries} Tracked Series
          </div>
        </div>
      )}

      {/* showing modal popup when study download starts */}
      <Modal
        title="Downloading"
        isOpen={isDownload}
        shouldCloseOnEsc={true}
        closeButton={true}
        onClose={() => {
          setIsDownload(false);
        }}
      >
        <div className="flex">
          <h4 className="mr-4">Please wait your study is downloading...</h4>
          <svg id="loading" viewBox="25 25 50 50">
            <circle id="loadingCircle" cx="50" cy="50" r="20"></circle>
          </svg>
          <span id="progress"></span>
        </div>
        <div className="flex justify-end">
          <Button
            color="primary"
            className="ml-2"
            onClick={() => {
              setIsDownload(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};

StudyItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StudyItem;

//function for downloading entire study
function downloadEntireStudy(callback) {
  const PACS_URL = new URL(
    dataSourceDetails.dataSourceDefs[
      dataSourceDetails.activeDataSource
    ].configuration.qidoRoot
  );

  fetch(
    PACS_URL['origin'] +
      '/' +
      dataSourceDetails.activeDataSource +
      '/tools/lookup',
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: window.location.href.split('=')[1],
    }
  )
    .then(res => res.json()) //here we are getting result in json response so that's why we need to parse it.
    .then(res => JSON.parse(JSON.stringify(res))[0].Path) //here we are parsing json response so that we can get the "Path" value.
    .then(res => {
      window.location.href =
        PACS_URL['origin'] +
        '/' +
        dataSourceDetails.activeDataSource +
        res +
        '/archive';
    })
    .finally(() => {
      setTimeout(() => {
        callback(false);
      }, 1500);
    });
}
//function for downloading entire study ends here
