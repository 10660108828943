import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select, Icon } from '../';

const baseLabelClassName = 'flex flex-col text-white text-lg select-none';
const spanClassName =
  'flex flex-row items-center cursor-pointer focus:outline-none';
const sortIconMap = {
  descending: 'sorting-active-up',
  ascending: 'sorting-active-down',
  none: 'sorting',
};

const InputMultiSelect = ({
  id,
  label,
  isSortable,
  sortDirection,
  onLabelClick,
  value,
  placeholder,
  options,
  onChange,
}) => {
  const onClickHandler = e => {
    if (!isSortable) {
      return;
    }
    onLabelClick(e);
  };

  return (
    <div className="flex">
      <Select
        id={id}
        placeholder={placeholder}
        className="mt-2"
        options={options}
        value={value}
        isMulti={true}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(selectedOptions, action) => {
          switch (action) {
            case 'select-option':
            case 'remove-value':
            case 'deselect-option':
            case 'clear':
              onChange(selectedOptions);
              break;
            default:
              break;
          }
        }}
      />
      <label
        className={classnames(baseLabelClassName)}
        style={{
          width: '24px',
          marginTop: '15px',
        }}
      >
        <span
          role="button"
          style={{ marginLeft: '-20px', position: 'absolute' }}
          className={spanClassName}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          tabIndex="0"
        >
          {/* {t(label)} */}
          {isSortable && (
            <Icon
              name={sortIconMap[sortDirection]}
              className={classnames(
                'mx-1 w-2',
                sortDirection !== 'none'
                  ? 'text-primary-light'
                  : 'text-primary-main'
              )}
            />
          )}
        </span>
        {/* <span>{children}</span> */}
      </label>
    </div>
  );
};

InputMultiSelect.defaultProps = {
  value: [],
  placeholder: 'Modality',
  options: [],
};

InputMultiSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isSortable: PropTypes.bool.isRequired,
  sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none'])
    .isRequired,
  onLabelClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  /** Array of options to list as options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /** Array of string values that exist in our list of options */
  value: PropTypes.arrayOf(PropTypes.string),
};

export default InputMultiSelect;
