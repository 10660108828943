import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DateRange, Icon } from '../';

const baseLabelClassName = 'flex flex-col text-white text-lg select-none';
const spanClassName =
  'flex flex-row items-center cursor-pointer focus:outline-none';
const sortIconMap = {
  descending: 'sorting-active-up',
  ascending: 'sorting-active-down',
  none: 'sorting',
};

const InputDateRange = ({
  id,
  label,
  isSortable,
  sortDirection,
  onLabelClick,
  value,
  onChange,
}) => {
  const { startDate, endDate } = value;

  const onClickHandler = event => {
    event.preventDefault();
    onLabelClick(event);
    onLabelClick(event);
    if (!isSortable) {
      return;
    }
  };

  return (
    <div className="flex relative">
      <DateRange
        id={id}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
      />
      <label
        className={classnames(baseLabelClassName)}
        style={{
          width: '24px',
          marginTop: '15px',
        }}
      >
        <span
          role="button"
          style={{ marginLeft: '-20px', position: 'absolute' }}
          className={spanClassName}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          tabIndex="0"
        >
          {/* {t(label)} */}
          {isSortable && (
            <Icon
              name={sortIconMap[sortDirection]}
              className={classnames(
                'mx-1 w-2',
                sortDirection !== 'none'
                  ? 'text-primary-light'
                  : 'text-primary-main'
              )}
            />
          )}
        </span>
        {/* <span>{children}</span> */}
      </label>
    </div>
  );
};

const noop = () => {};

InputDateRange.defaultProps = {
  value: {},
  onLabelClick: noop,
};

InputDateRange.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  isSortable: PropTypes.bool.isRequired,
  sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none'])
    .isRequired,
  onLabelClick: PropTypes.func.isRequired,
  value: PropTypes.shape({
    /** YYYYMMDD (19921022) */
    startDate: PropTypes.string,
    /** YYYYMMDD (19921022) */
    endDate: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default InputDateRange;
